import React, { useState } from "react";
import BIM4D from "../../components/_Landing/4DBIM";
import SEO from "../../components/Seo";

const Services4D = () => {
  const [showModal, setShowModal] = useState(true);
  const [category, setCategory] = useState<string | null>(null);
  return <BIM4D />;
};

export default Services4D;

export const Head = ({ location }) => (
  <SEO
    title={
      "4D BIM Services in Singapore | Develop Construction Sequences from BIM"
    }
    description="A 4D BIM models are widely used for site planning & tender presentations. Engage our 4D BIM services to create detailed construction sequences for your next project."
    pathname={location.pathname}
  />
);
