import React, { useContext, useRef, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import HowItWorks from "./HowItWorks";
import MC3D from "assets/svgs/mingcute_cube-3d-line.svg";
import BiFolderX from "assets/svgs/bi_folder-x.svg";
import ArrowCube from "assets/svgs/arrow-cube.svg";
import BiFolder3D from "assets/svgs/bi_folder-3d.svg";
import VaddinChart3D from "assets/svgs/vaadin_chart-3d.svg";
import { SiAutodeskrevit, SiAutocad } from "react-icons/si";
import { FaBuildingCircleCheck, FaTimeline } from "react-icons/fa6";
import { PiPipeFill } from "react-icons/pi";
import { FaBimobject, FaFileInvoiceDollar } from "react-icons/fa";
import { BsBadgeVr } from "react-icons/bs";
import Portfolio from "./Portfolio";
import Feedback from "components/Feedback";
import Modal3D from "assets/svgs/threed-model.svg";
import ConstructionSiteFlatline from "assets/svgs/construction-site-flatline.svg";
import ConstructionSiteOutline from "assets/svgs/construction-site-outline.svg";
import Footer from "components/Footer";
import { navigate } from "gatsby";
import { GlobalDispatchContext } from "../../../context/GlobalContextProvider";
import { CTA } from "./CTA";
import { faq } from "./data";
import TwoColumn from "components/TwoColumn";
import ThreeColumn from "components/ThreeColumn";
import "swiper/css";

const BIM4D = () => {
  const [showModal, setShowModal] = useState(false);
  const [category, setCategory] = useState("");
  const dispatch = useContext(GlobalDispatchContext);

  const handleDefault = () => {
    navigate(`mailto:enquiry@bim.com.sg?subject=4D%20BIM%20Services`);
  };

  const imagedata = useStaticQuery(graphql`
    {
      tender: file(relativePath: { eq: "4d-tender-3.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      site: file(relativePath: { eq: "4d-tender-2.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  let sections = [
    {
      id: "gtm-bim",
      title: "Advanced Visualizations",
      content: `Create detailed construction sequences from BIM for tender presentations, project planning, and stakeholder engagement.`,
      linkText: "4D BIM Use Case",
      linkHref:
        "https://blog.bim.com.sg/what-is-4d-bim-and-how-can-it-transform-the-construction-management-process/",
      src: imagedata.tender,
      bgColor: "bg-white",
    },
    {
      id: "gtm-review",
      title: "Virtual Site Planning",
      content: `Visualize construction sequences in context - bring in survey data and BIM models to create a virtual construction site.`,
      linkText: null,
      linkHref: null,
      src: imagedata.site,
    },
  ];

  let services = [
    {
      id: "gtm-bim",
      img: "./3d-bim-black.svg",
      title: "BIM Modelling",
      description:
        "We keep BIM models up-to-date with the latest project changes.",
      href: "/services/bim-modelling",
      icon: <FaBuildingCircleCheck size={60} />,
    },
    {
      id: "gtm-time",
      img: "./4d-bim-black.svg",
      title: "Native BIM Support",
      description:
        "We support multiple BIM software including Revit, ArchiCAD, and more.",
      href: "/services/design-reviews",
      icon: <SiAutodeskrevit size={60} />,
    },
    {
      id: "gtm-cost",
      img: "./5d-bim-black.svg",
      title: "Schedule Development",
      description:
        "Import multiple schedule formats like Microsoft Projects or Primavera P6 into BIM",
      href: "/services/construction-drawings",
      icon: <FaTimeline size={60} />,
    },
    {
      id: "gtm-clash",
      img: "./clash-detection-black.png",
      title: "Multiple Disciplines",
      description:
        "Develop ACMV, Electrical, Fire Protection, and Structural models for clash detection.",
      href: "/services/clash-detection",
      icon: <PiPipeFill size={60} />,
    },
    {
      id: "gtm-verify",
      img: "./as-built-verification-black.svg",
      title: "Quality Visuals",
      description:
        "Create high-quality visuals as part of the BIM model for client presentations.",
      href: "/services/ifc-submissions",
      icon: <BsBadgeVr size={60} />,
    },
    {
      id: "gtm-scan",
      img: "./scan-bim-black.svg",
      title: "BIM Software",
      description:
        "Our specialists are equipped with the latest BIM software and tools.",
      href: "https://blog.bim.com.sg/applications-of-drone-photogrammetry-in-construction-sites/",
      icon: <FaFileInvoiceDollar size={60} />,
    },
  ];

  return (
    <div>
      {/* Header */}
      <main>
        {/* Hero Section */}
        <section className="pt-10 md:pt-[57px] pb-9 bg-gray-50">
          <div className="tw-container">
            <div className="relative z-0">
              <BiFolderX className="absolute top-0 hidden left-10 md:block -z-10" />
              <ArrowCube className="absolute left-0 -bottom-[130px] w-[340px] md:block hidden -z-10" />
              <BiFolder3D className="absolute top-0 hidden right-10 md:block -z-10" />
              <VaddinChart3D className="absolute bottom-0 right-0 hidden md:block -z-10" />
              <div className="text-center max-w-[810px] mx-auto">
                <label className="inline-flex items-center gap-1 py-1 px-2 md:px-3 rounded-full bg-main-primary text-xs text-black font-medium tracking-[0.3px] uppercase mb-4 md:mb-6">
                  <MC3D />
                  Services
                </label>
                <h1 className="mb-5 text-4xl font-extrabold text-black md:text-5xl">
                  4D BIM Services
                </h1>
                <p className="mb-0 text-sm text-gray-500 md:text-base lg:text-xl">
                  Create detailed construction sequences from BIM for
                  tender presentations, project planning, and
                  stakeholder engagement.
                </p>
                <div className="flex justify-center items-center gap-2 mt-5 md:mt-8 lg:mt-10 md:gap-4">
                  <button
                    type="button"
                    className="transition-all duration-300 ease-in-out hover:opacity-80 text-sm md:text-base text-white font-medium capitalize rounded-md shadow-shadow-sm bg-black py-[13px] px-4 md:px-[25px]"
                    onClick={() => navigate(`./#how-it-works`)}
                  >
                    How it Works
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Why Choose Us*/}
        <ThreeColumn id="why-us" services={services} />

        {/* Workflows*/}
        <TwoColumn
          title="Key Benefits"
          description="Benefits of 4D BIM for Project Delivery"
          sections={sections}
        />

        {/* Our Services */}
        <HowItWorks id="how-it-works" handleDefault={handleDefault} />

        {/* Portfolio */}

        {/* FAQ */}
        <section className="py-10 md:py-16 bg-gray-50" id="faq">
          <div className="tw-container">
            <h2 className="text-md text-black font-extrabold -tracking-[0.9px] text-left mb-0">
              Frequently asked questions
            </h2>

            <div className="pb-5 mt-5 space-y-5 border-0 border-b border-gray-200 border-solid md:mt-6 lg:mt-8 md:pb-6 lg:pb-8 md:space-y-6 lg:space-y-8">
              {faq.map((faq) => (
                <div
                  className="flex flex-col items-start gap-3 pt-4 border-0 border-t border-gray-200 border-solid md:gap-6 lg:gap-8 md:pt-5 lg:pt-6 md:flex-row"
                  key={faq.id}
                  id={faq?.section}
                >
                  <div className="md:w-[300px] lg:w-[488px]">
                    <h4 className="mb-0 text-base font-semibold text-black ">
                      {faq.question}
                    </h4>
                  </div>
                  <div className="flex-1">
                    <p className="mb-0 text-sm text-gray-500 md:text-base">
                      {faq.answer}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <Compare /> */}
        </section>

        {/* CTA */}
        <CTA handleClick={handleDefault} />
      </main>

      <Footer />
    </div>
  );
};

export default BIM4D;
